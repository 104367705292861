import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "tagList", "hiddenInput"];

  declare inputTarget: HTMLInputElement;
  declare tagListTarget: HTMLElement;
  declare hiddenInputTarget: HTMLInputElement;

  private tags: string[] = [];

  connect(): void {
    const initialTags = this.tagListTarget.dataset.tagInputInitialTags;
    this.tags = initialTags
      ? initialTags.split(",").map((tag) => tag.trim())
      : [];
    this.renderTags();
    this.updateHiddenInput();
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === "Enter" && this.inputTarget.value) {
      event.preventDefault();
      this.addTag();
    }
  }

  addTag(): void {
    const tagValue = this.inputTarget.value.trim();
    if (tagValue && !this.tags.includes(tagValue)) {
      this.tags.push(tagValue);
      this.inputTarget.value = "";
      this.renderTags();
      this.updateHiddenInput();
    }
  }

  removeTag(event: Event): void {
    const target = event.target as HTMLElement;
    const tagElement = target.closest(".tag") as HTMLElement;
    if (tagElement) {
      const tagToRemove = tagElement.dataset.tag;
      if (tagToRemove) {
        this.tags = this.tags.filter((tag) => tag !== tagToRemove);
        this.renderTags();
        this.updateHiddenInput();
      }
    }
  }

  private renderTags(): void {
    this.tagListTarget.innerHTML = this.tags
      .map(
        (tag) => `
      <span class="tag bg-gray-200 text-sm py-1 px-2 rounded-full flex items-center" data-tag="${tag}">
        ${tag}
        <button
          data-action="click->tag-input#removeTag"
          class="ml-2 focus:outline-none"
          aria-label="Remove ${tag} tag"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </span>
    `,
      )
      .join("");
  }

  private updateHiddenInput(): void {
    this.hiddenInputTarget.value = this.tags.join(",");
  }
}
