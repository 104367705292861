import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "indicator"];

  declare containerTarget: HTMLElement;
  declare indicatorTarget: HTMLElement;

  connect() {
    this.updateScrollIndicator();
    this.containerTarget.addEventListener(
      "scroll",
      this.updateScrollIndicator.bind(this),
    );
  }

  prev() {
    this.containerTarget.scrollBy({
      left: -this.containerTarget.offsetWidth,
      behavior: "smooth",
    });
  }

  next() {
    this.containerTarget.scrollBy({
      left: this.containerTarget.offsetWidth,
      behavior: "smooth",
    });
  }

  updateScrollIndicator() {
    const scrollPosition = this.containerTarget.scrollLeft;
    const maxScroll =
      this.containerTarget.scrollWidth - this.containerTarget.clientWidth;
    const scrollPercentage = scrollPosition / maxScroll;
    const imageCount = this.indicatorTarget.children.length;
    const activeIndex = Math.round(scrollPercentage * (imageCount - 1));

    Array.from(this.indicatorTarget.children).forEach((indicator, index) => {
      if (index === activeIndex) {
        indicator.classList.add("bg-white");
        indicator.classList.remove("bg-opacity-50");
      } else {
        indicator.classList.remove("bg-white");
        indicator.classList.add("bg-opacity-50");
      }
    });
  }
}
